<template>
	<div
		id="root"
		:class="{monospace, wide, 'show-navigation': showNavigation, darkMode, loading, isAtTop}"
		@click="showNavigation = false">
		<nav @click.self.stop="showNavigation = !showNavigation">
			<Logo />
			<div class="datum" @click="wide = !wide">{{ huidigeWeek }}</div>
			<ul>
				<li>
					<NuxtLink to="/"
						><i class="pi pi-home" accesskey="h" /> <span style="font-weight: bold">Start</span></NuxtLink
					>
				</li>

				<li v-if="!session.identity">
					<a href="/api/session/login" accesskey="i"><i class="pi pi-key" /> Inloggen</a>
				</li>

				<template v-if="session.identity">
					<li>
						<NuxtLink to="/zoeken" accesskey="o"><i class="pi pi-search" /> Zoeken</NuxtLink>
					</li>
				</template>

				<li v-if="jaar && jarenData">
					<a>
						<select v-model="jaar" :disabled="!('jaar' in route.params)" style="margin-left: 25px; width: 180px">
							<option v-for="jaar in jarenData.jaren" :value="jaar">{{ jaar }}</option>
						</select>
					</a>
				</li>
			</ul>

			<template v-if="session.identity">
				<ul style="margin-top: 30px">
					<template v-if="medewerker">
						<li>
							<template v-if="canViewOthers && 'medewerker' in route.params">
								<a>
									<i class="pi pi-user" />
									<select @click.stop v-model="medewerker" style="width: 180px; font-weight: bold">
										<option disabled>Kies medewerker...</option>
										<option v-for="mw in medewerkersData.medewerkers" :value="mw.medewerker">
											{{ mw.weergaveNaam }}
										</option>
									</select>
								</a>
							</template>
							<template v-else>
								<NuxtLink :to="'/medewerker/' + medewerker"
									><i class="pi pi-user" />
									<span style="font-weight: bold">&nbsp;{{ medewerkerNaam }}</span>
								</NuxtLink>
							</template>
						</li>

						<li v-if="isMT || isAdministratie || isHRM">
							<NuxtLink :to="'/medewerker/' + medewerker + '/gegevens'" accesskey="m"
								><i class="pi pi-id-card" /> Gegevens</NuxtLink
							>
						</li>
						<li v-if="jaar">
							<NuxtLink :to="'/medewerker/' + medewerker + '/' + jaar + '/declaraties'" accesskey="e"
								><i class="pi pi-ticket" /> Declaraties</NuxtLink
							>
						</li>
						<li>
							<NuxtLink :to="'/medewerker/' + medewerker + '/dienstverband'" accesskey="d"
								><i class="pi pi-folder" /> Dienstverband</NuxtLink
							>
						</li>
						<li v-if="jaar">
							<NuxtLink :to="'/medewerker/' + medewerker + '/' + jaar + '/feestdagen'" accesskey="f"
								><i class="pi pi-flag" /> Feestdagen</NuxtLink
							>
						</li>

						<li>
							<NuxtLink :to="'/medewerker/' + medewerker + '/planning'" accesskey="p"
								><i class="pi pi-calendar" /> Planning</NuxtLink
							>
						</li>

						<li>
							<NuxtLink :to="'/medewerker/' + medewerker + '/projectenlijst'" accesskey="p"
								><i class="pi pi-list" /> Projectenlijst</NuxtLink
							>
						</li>

						<li v-if="jaar">
							<NuxtLink :to="'/medewerker/' + medewerker + '/' + jaar" accesskey="u"
								><i class="pi pi-clock" /> Uren</NuxtLink
							>
						</li>
						<li v-if="jaar">
							<NuxtLink :to="'/medewerker/' + medewerker + '/' + jaar + '/verlof'" accesskey="v"
								><i class="pi pi-sun" /> Verlof</NuxtLink
							>
						</li>
					</template>
				</ul>
			</template>

			<div style="flex: 1">&nbsp;</div>

			<ul v-if="session.identity">
				<li>
					<form method="POST" action="/api/session/logout">
						<a href="javascript:void(0);" @click="$event.target.parentNode.submit()" accesskey="x"
							><i class="pi pi-power-off" /> Uitloggen</a
						>
					</form>
				</li>
			</ul>
		</nav>
		<div class="spinner"></div>
		<main :class="{loading}">
			<!-- Links terug naar hoofdpagina vanaf hoofdpagina -->
			<NuxtLink v-if="route.path.match(/^\/[a-z]+$/)" to="/" class="back">Start</NuxtLink>

			<!-- Linkjes terug naar de hoofdpagina binnen categorie -->
			<NuxtLink v-if="isSalaris && route.path.match(/^\/beloning\//)" to="/beloning" class="back">Beloning</NuxtLink>
			<NuxtLink v-if="isSalaris && isMT && route.path.match(/^\/resultaat\//)" to="/resultaat" class="back"
				>Resultaat</NuxtLink
			>
			<NuxtLink v-if="isMT && route.path.match(/^\/mt\//)" to="/mt" class="back">Projectuitvoering</NuxtLink>
			<NuxtLink v-if="(isICT || isMT) && route.path.match(/^\/ict\//)" to="/ict" class="back">ICT</NuxtLink>

			<NuxtLink v-if="(isHRM || isMT) && route.path.match(/^\/hrm\/contracten\/.+/)" to="/hrm/contracten" class="back"
				>Alle dienstverbanden</NuxtLink
			>
			<NuxtLink v-else-if="(isHRM || isMT) && route.path.match(/^\/hrm\//)" to="/hrm" class="back"
				>Personeel &amp; organisatie</NuxtLink
			>
			<NuxtLink v-else-if="(isAcquisitie || isMT) && route.path.match(/^\/acquisitie\//)" to="/acquisitie" class="back"
				>Acquisitie</NuxtLink
			>
			<NuxtLink v-else-if="(isKwaliteit || isMT) && route.path.match(/^\/kwaliteit\//)" to="/kwaliteit" class="back"
				>Kwaliteitsmanagement</NuxtLink
			>

			<NuxtLink
				v-if="(isCommunicatie || isMT) && route.path.match(/^\/communicatie\//)"
				to="/communicatie"
				class="back">
				Communicatie</NuxtLink
			>
			<NuxtLink
				v-if="(isAdministratie || isMT) && route.path.match(/^\/(administratie|debiteur|crediteur|factuur|inkoop)\//)"
				to="/administratie"
				class="back"
				>Administratie &amp; financiën</NuxtLink
			>
			<NuxtLink v-if="(isAdministratie || isMT) && route.path.match(/^\/(offerte)\//)" to="/offerte" class="back"
				>Alle offertes/projecten</NuxtLink
			>

			<!-- Pagina-inhoud -->
			<NuxtPage />
		</main>
	</div>
</template>

<script setup>
import {useAuthenticated} from "./composables/crud";
import {getISOWeek} from "./shared";

const {data: session} = await useAuthenticated("/api/session/info");

const route = useRoute();
const router = useRouter();

const showNavigation = ref(false);
const huidigeWeek = getISOWeek(new Date());

const jaar = ref(route.params.jaar ? parseInt(route.params.jaar, 10) : new Date().getFullYear());
const cookieExpiry = new Date();
cookieExpiry.setTime(cookieExpiry.getTime() + 86400 * 365 * 1000);

const monospace = useCookie("opus.monospace", {expires: cookieExpiry});
monospace.value = monospace.value === true;
provide("monospace", monospace);

const wide = useCookie("opus.wide", {expires: cookieExpiry});
wide.value = wide.value === true;

const isMasking = ref(true);
provide("isMasking", isMasking);

const isDarkModePreferred = useCookie("opus.darkMode", null);

const darkMode = computed(() => {
	return !!isDarkModePreferred.value;
});
provide("isDarkModePreferred", isDarkModePreferred);

const showSmilies = useCookie("opus.smilies", {expires: cookieExpiry});
provide(
	"showSmilies",
	computed({
		get() {
			return showSmilies.value !== false;
		},
		set(nv) {
			showSmilies.value = !!nv;
		}
	})
);

const medewerker = computed({
	get() {
		return route.params.medewerker ? route.params.medewerker : session.value.identity?.medewerker || null;
	},
	set(nv, ov) {
		if (nv !== ov) {
			router.push({params: {...route.params, medewerker: nv}});
		}
	}
});

provide("medewerker", medewerker);

const {data: jarenData} = await useAuthenticated("/api/jaren");
const {data: medewerkersData, pending: medewerkersPending} = await useAuthenticatedReactive(() => {
	return "/api/medewerkers?including=" + encodeURIComponent(medewerker.value);
});

const medewerkerNaam = computed(() => {
	if (!medewerker.value || medewerkersPending.value) return "Mijn overzicht";
	return (
		medewerkersData.value.medewerkers.find((x) => x.medewerker === medewerker.value)?.weergaveNaam || "Mijn overzicht"
	);
});

provide("session", session);

const isSalaris = computed(() => {
	return session.value && session.value.identity && session.value.identity.privileges.includes("salaris");
});
provide("isSalaris", isSalaris);

const isMT = computed(() => {
	return session.value && session.value.identity && session.value.identity.privileges.includes("mt");
});
provide("isMT", isMT);

const isAdministratie = computed(() => {
	return session.value && session.value.identity && session.value.identity.privileges.includes("administratie");
});
provide("isAdministratie", isAdministratie);

const isCommunicatie = computed(() => {
	return session.value && session.value.identity && session.value.identity.privileges.includes("communicatie");
});
provide("isCommunicatie", isCommunicatie);

const isHRM = computed(() => {
	return session.value && session.value.identity && session.value.identity.privileges.includes("hrm");
});
provide("isHRM", isHRM);

const isICT = computed(() => {
	return session.value && session.value.identity && session.value.identity.privileges.includes("ict");
});
provide("isICT", isICT);

const isAdresGegevens = computed(() => {
	return session.value && session.value.identity && session.value.identity.privileges.includes("adresgegevens");
});
provide("isAdresGegevens", isAdresGegevens);

const isAcquisitie = computed(() => {
	return session.value && session.value.identity && session.value.identity.privileges.includes("acquisitie");
});
provide("isAcquisitie", isAcquisitie);

const isKwaliteit = computed(() => {
	return session.value && session.value.identity && session.value.identity.privileges.includes("kwaliteit");
});
provide("isKwaliteit", isKwaliteit);

const canViewOthers = computed(() => {
	return isMT.value || isAdministratie.value || isHRM.value;
});

watch(
	() => route.params.jaar,
	(nv) => {
		const j = parseInt(nv, 10);
		if (!isNaN(j) && j > 2000) {
			jaar.value = j;
		}
	}
);

watch(jaar, (j, oldJaar) => {
	if (j !== oldJaar) {
		router.push({params: {...route.params, jaar: j}});
	}
});

// Loading indicator
const nuxtApp = useNuxtApp();
const loading = ref(false);
nuxtApp.hook("page:start", () => {
	loading.value = true;
});

nuxtApp.hook("page:finish", () => {
	loading.value = false;
});

const isPrintMedia = ref(false);
provide("isPrintMedia", isPrintMedia);

// Watch media query changes
onMounted(() => {
	if (matchMedia) {
		const mq = window.matchMedia("print");
		isPrintMedia.value = mq.matches;

		mq.addEventListener("change", (evt) => {
			isPrintMedia.value = evt.matches;
		});

		if (isDarkModePreferred.value === null) {
			const mq = window.matchMedia("(prefers-color-scheme: dark)");
			isDarkModePreferred.value = mq.matches;
		}
	}

	updateDarkMode();
});

function updateDarkMode() {
	if (isDarkModePreferred.value) {
		document.body.classList.add("darkMode");
	} else {
		document.body.classList.remove("darkMode");
	}
}

watch(isDarkModePreferred, (v) => {
	updateDarkMode();
});

// Scrolling
const isAtTop = ref(true);

function debounce(fn) {
	let frame;

	return (...params) => {
		if (frame) {
			cancelAnimationFrame(frame);
		}
		frame = requestAnimationFrame(() => {
			fn(...params);
		});
	};
}

const updateIsAtTop = debounce(() => {
	// Apple can scroll below zero
	isAtTop.value = window.scrollY <= 0;
});

onBeforeMount(() => {
	document.addEventListener("scroll", updateIsAtTop, {passive: true});
});

onBeforeUnmount(() => {
	document.removeEventListener("scroll", updateIsAtTop, {passive: true});
});

useHead({
	titleTemplate: (titleChunk) => {
		return titleChunk ? `${titleChunk} - Dialogic` : "Dialogic";
	}
});
</script>

<style>
:root {
	--nav-width: 240px;
	--font-family: Inter, Verdana, sans-serif !important;
	--text-color: rgb(93, 61, 61);
	--background: rgb(243, 243, 245);
	--heading-color: rgb(0, 55, 100);
	--header-color: rgb(0, 55, 100);
	--footer-text-color: rgb(0, 55, 100);
	--heading-inverse-color: white;
	--header-inverse-color: white;
	--header-secondary-color: rgb(187, 224, 227);
	--link-color: rgb(0, 55, 100);
	--box-background: rgba(255, 255, 255, 0.8);
	--nav-margin: 20px;
	--left-sticky: calc(var(--nav-width) + var(--nav-margin));
	--monospace-font: "Fira code", Consolas, "Courier New", Courier, monospace;
	--monospace-size: 9pt;
	--uneven-background: white;
	--even-background: rgba(0, 0, 0, 0.03);
	--popped-background-color: white;
	--footer-background-color: rgba(220, 220, 220, 1);
	--footer-background-color-transparent: rgba(220, 220, 220, 0.5);
	--free-dark-color: rgb(240, 240, 230);
	--free-light-color: rgb(250, 250, 240);
	--hover-color: rgba(187, 224, 227, 0.6);
	--active-color: rgba(187, 224, 227, 0.3);
	--shadow-color: rgba(0, 0, 0, 0.05);
	--filter-background-color: rgb(220, 220, 220);
	--popup-border: solid 1px #8dbcaa;
	--input-border: solid 1px #8dbcaa;

	--button-color: rgb(231, 245, 249);
	--button-border-color: rgb(143, 207, 227);
	--alert-background-color: rgb(240, 240, 200);
	--button-hover-border: solid 1px rgb(0, 55, 100);
}

.darkMode {
	--background: rgb(0, 0, 0);
	--heading-inverse-color: rgb(40, 40, 40);
	--text-color: rgb(197, 197, 197);
	--heading-color: rgb(187, 224, 227);
	--header-color: rgb(30, 30, 30);
	--footer-text-color: rgb(187, 224, 227);
	--header-inverse-color: rgb(197, 197, 197);
	--link-color: rgb(187, 224, 227);
	--box-background: rgba(30, 30, 30, 0.8);
	--uneven-background: black;
	--even-background: rgba(30, 30, 30, 0.03);
	--popped-background-color: rgb(30, 30, 30);
	--footer-background-color: rgba(60, 60, 60, 1);
	--footer-background-color-transparent: rgba(60, 60, 60, 0.5);
	--free-dark-color: rgb(30, 30, 10);
	--free-light-color: rgb(50, 50, 20);
	--hover-color: rgba(30, 30, 30, 0.6);
	--filter-background-color: rgb(30, 30, 30);

	--popup-border: solid 1px #8dbcaa;
	--input-border: solid 1px #8dbcaa;

	--button-color: rgb(30, 30, 30);
	--button-border-color: rgb(50, 50, 50);
	--alert-background-color: rgb(20, 20, 0);
	--button-hover-border: solid 1px rgb(150, 150, 150);
}
</style>

<style scoped>
nav select {
	background-color: transparent;
	padding: 3px;
	padding-left: 0;
	border: none;
}

#root.loading .spinner {
	transition-delay: 0.5s;
	transition-property: opacity;
	transition-duration: 1s;
	opacity: 1;
}

#root .spinner {
	min-width: 0;
	display: block;
	--spinner-size: 64px;
	opacity: 0;
	transition-property: opacity;
	position: fixed;
	z-index: 1000;
	top: calc(33% - var(--spinner-size));
	left: calc(50% - var(--spinner-size) / 2 + var(--nav-width) / 2);
	pointer-events: none;
}

@media only screen and (max-width: 1000px) {
	#root .spinner {
		/* Navigation is hidden in mobile mode, so the spinner should be centered in the full viewport */
		left: calc(50% - var(--spinner-size) / 2) !important;
	}
}

#root .spinner {
	--spinner-color: #003764;
	width: 48px;
	height: 40px;
	margin-top: 30px;
	display: inline-block;
	background: linear-gradient(45deg, rgba(187, 224, 227, 0.5), rgba(0, 55, 100, 0.7));
	border-radius: 15% 15% 35% 35%;
}

#root.darkMode .spinner {
	background: rgb(30, 30, 30);
}

#root.darkMode .spinner::after {
	border: 4px solid rgb(30, 30, 30);
}

#root.darkMode .spinner::before {
	color: rgb(30, 30, 30);
}

#root .spinner::after {
	content: "";
	box-sizing: border-box;
	position: absolute;
	left: 48px;
	top: 8px;
	border: 4px solid rgba(0, 55, 100, 0.5);
	border-left: none;
	width: 10px;
	height: 20px;
	border-radius: 0 4px 4px 0;
}

#root .spinner::before {
	content: "";
	position: absolute;
	width: 1px;
	height: 10px;
	color: var(--spinner-color);
	top: -15px;
	left: 11px;
	box-sizing: border-box;
	animation: animloader 1s ease infinite;
}

@keyframes animloader {
	0% {
		box-shadow: 2px 0px rgba(255, 255, 255, 0), 12px 0px rgba(255, 255, 255, 0.3), 20px 0px rgba(255, 255, 255, 0);
	}
	50% {
		box-shadow: 2px -5px rgba(0, 55, 100, 0.5), 12px -3px rgba(0, 55, 100, 0.5), 20px -2px rgba(0, 55, 100, 0.6);
	}
	100% {
		box-shadow: 2px -8px rgba(0, 55, 100, 0), 12px -5px rgba(0, 55, 100, 0), 20px -5px rgba(0, 55, 100, 0);
	}
}

@media screen {
	#root:not(.monospace) {
		--monospace-font: var(--font-family);
	}

	#root {
		color: var(--text-color);
		background-color: var(--background);
	}

	#root > main {
		margin: 0 0;
		margin-left: calc(var(--nav-width) + 2 * var(--nav-margin));
		padding: 15px;
		box-sizing: border-box;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		width: calc(100% - var(--nav-width) - 3 * var(--nav-margin));
		height: 100%;
		position: relative;
	}

	#root:deep(main > *) {
		width: 80%;
		max-width: 1000px;
		min-width: 800px;
	}

	#root.wide:deep(main > *) {
		width: calc(100vw - var(--nav-width) - 50px);
		max-width: calc(100vw - var(--nav-width) - 50px);
	}

	#root > nav {
		background-color: var(--box-background);
		display: block;
		width: var(--nav-width);
		left: 0px;
		top: 0px;
		height: 100%;
		position: fixed;
		padding: 0px;
		padding-top: 10px;
		margin: 0;
		border-radius: 0;
		box-sizing: border-box;
		box-shadow: 1px 1px 10px var(--shadow-color);
		border-right: solid 1px var(--shadow-color);
		z-index: 1000;
		backdrop-filter: blur(11px);
	}

	#root > nav > dl {
		margin-left: 10px;
	}

	#root > nav > ul {
		margin: 0;
		padding: 0;
		margin-top: 3px;
		list-style-type: none;
	}

	#root > nav > ul > li a {
		text-decoration: none;
		display: block;
		height: 2em;
		line-height: 1.5em;
		padding: 0.25em;
		padding-left: 17px;
		border-radius: 3px;
		margin-left: 3px;
		margin-right: 3px;
	}

	#root > nav > ul > li a i {
		margin-right: 5px;
		margin-top: 3px;
	}

	#root > nav > ul > li a.router-link-exact-active {
		background-color: var(--active-color);
	}

	#root > nav > ul > li a:hover {
		background-color: var(--hover-color);
	}

	main {
		position: relative;
	}

	nav li h3 {
		font-size: 9pt;
		margin-left: 10px;
		color: var(--heading-color);
		margin-bottom: 3px;
		position: relative;
		text-shadow: 0px 0px 2px var(--popped-background-color);
		z-index: 1;
	}

	div.datum {
		position: absolute;
		left: 10px;
		bottom: 10px;
		opacity: 0.5;
		font-size: 64pt;
		color: rgba(0, 55, 100, 0.5);
	}

	:deep(.back) {
		color: var(--text-color) !important;
		font-size: 11pt;
		text-decoration: none;
		top: 10px;
		position: relative;
	}

	:deep(.back:before) {
		content: "← ";
	}
}
</style>
